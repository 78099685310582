import React from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const SimuladorEconomia = () => {
    return (
        <>
            <Helmet>
                <html lang="pt-BR" amp/>
                <meta charSet="UTF-8"/>
                <title>Cia. Ibérica - Contato</title>
                <link rel="canonical" href="https://www.ciaiberica.com.br/contato/"/>
                <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large"/>
                <meta property="og:locale" content="pt-BR"/>
                <meta property="og:title" content="Cia. Ibérica - Contato"/>
                <meta property="og:description"
                      content="Entre em contato com a Cia. Ibérica, empresa brasileira e com tradição no mercado."/>
                <meta property="og:url" content="https://www.ciaiberica.com.br/contato/"/>
                <meta property="og:sitename" content="Cia. Ibérica"/>
                <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            </Helmet>
            <Header/>
                
            <Footer/>
        </>
    )
}

export default SimuladorEconomia